/* global tw */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';

const Wrapper = styled.a`
  width: 100%;
  ${tw('shadow-lg relative no-underline rounded-lg px-8 py-8 md:py-24 text-white')};
  background: ${props => props.bg};
  transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform: translateY(0px);
  &:hover {
    transform: translateY(-5px);
  }
  overflow: hidden;
`;

const Text = styled.div`
  ${tw('opacity-75 font-sans text-sm md:text-base pt-8')};
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
`;

const Title = styled.div`
  ${tw('text-white uppercase text-2xl md:text-3xl xl:text-4xl font-medium font-sans')};
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
`;

const BackgroundImage = styled.img`
  ${tw('h-full w-auto')};
  -webkit-mask-image: radial-gradient(ellipse 100% 100% at 50% 50%, black 40%, transparent 50%);
  mask-image: radial-gradient(ellipse 100% 100% at 50% 50%, black 0%, transparent 60%);
  opacity: 0.3;
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: -1;
`;

const ProjectCard = ({ title, link, children, bg, bgIcon }) => (
  <Wrapper href={link} target="_blank" rel="noopener noreferrer" bg={bg}>
    <div>
      <Title>{title}</Title>
      <Text>{children}</Text>
      <BackgroundImage src={bgIcon} alt="icon"/>
    </div>
  </Wrapper>
);

export default ProjectCard;

ProjectCard.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  bg: PropTypes.string.isRequired,
  bgIcon: PropTypes.string.isRequired,
};
