const tailwind = require('../tailwind');

module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: 'Yerai Alonso - Street Workout & Calistenia', // Navigation and Site Title
  siteTitleAlt: 'Yerai Alonso', // Alternative Site title for SEO
  siteUrl: 'https://yeraialonso.com', // Domain of your site. No trailing slash!
  siteLanguage: 'es', // Language Tag on <html> element
  siteLogo: '/logos/logo-1024.png', // Used for SEO and manifest
  siteDescription: 'Atleta de Calistenia y Street Workout, con más de 6 años de experiencia, creador de uno de los canales de YouTube más influyentes en el sector así como de la aplicación de calistenia más reconocida. Autor del libro “Guía Completa de Calistenia y Street Workout”, profesor de cursos de calistenia nivel nacional, jurado de competiciones de calistenia a nivel nacional e internacional, doble campeón de Canarias y competidor a nivel nacional.',

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  //userTwitter: '@yeraialonso', // Twitter Username
  //ogSiteName: 'cara', // Facebook Site Name
  ogLanguage: 'es_ES', // Facebook Language

  // Manifest and Progress color
  themeColor: tailwind.colors.black,
  backgroundColor: tailwind.colors.black,
};
