/* global tw */
import React from 'react';
import styled from 'react-emotion';
import 'typeface-montserrat';
import 'typeface-rubik';
// import windowSize from 'react-window-size';
import SEO from '../components/SEO';
import SVG from '../components/SVG';
import ProjectCard from '../components/ProjectCard';
import { UpDown, UpDownWide } from '../styles/animations';
import { hidden } from '../styles/utils';
import { colors } from '../../tailwind';
import avatar from '../images/avatar.jpg';
import background_fragment_one from '../images/header_background.jpg';
import bookImage from '../images/book.jpg';
import blogImage from '../images/blog.jpg';
import foroImage from '../images/foro.jpg';
import podcastImage from '../images/podcast.jpg';
import instagramImage from '../images/instagram.jpg';
import youtubeImage from '../images/youtube.jpg';
import prozisImage from '../images/planche.jpg';
import '../styles/global';

const Divider = styled.div`
	${tw('absolute w-full h-full')};
	background: ${(props) => props.bg};
	svg {
		fill: ${(props) => props.fill};
	}
	clip-path: ${(props) => props.clipPath};
`;

const Content = styled.div`${tw('p-6 md:p-12 lg:p-24 justify-center items-center flex z-50')};`;

const Hero = styled.div`${tw('w-full xl:w-3/4')};`;

const Inner = styled.div`${tw('w-full xl:w-3/4 xxl:w-2/3 text-center lg:text-left')};`;

const BigTitle = styled.h1`
	${tw('text-4xl font-serif leading-none text-white mb-0 tracking-wide')};
	text-shadow: 0 5px 35px rgba(255, 255, 255, 0.15);
`;

const Title = styled.h1`
	${tw('justify-center font-serif font-extrabold italic text-white mb-8 tracking-wide')};
	text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
`;

const Subtitle = styled.p`
	${tw('font-serif font-light uppercase leading-none text-white')};
	text-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
`;

const ProjectsWrapper = styled.div`
	${tw('flex flex-wrap justify-between mt-8')};
	display: grid;
	grid-gap: 4rem;
	grid-template-columns: repeat(2, 1fr);
	@media (max-width: 1200px) {
		grid-gap: 3rem;
	}
	@media (max-width: 900px) {
		grid-template-columns: 1fr;
		grid-gap: 2rem;
	}
`;

const AboutHero = styled.div`${tw('flex flex-col items-center mt-8')};`;
const Avatar = styled.img`${tw('rounded-full w-32 xl:w-48 shadow-lg h-auto')};`;

const BackgroundLeft = styled.img`
	${tw('h-full w-full')};
	-webkit-mask-image: radial-gradient(ellipse 90% 85% at 20% 50%, black 0%, transparent 60%);
	mask-image: radial-gradient(ellipse 90% 85% at 20% 50%, black 0%, transparent 60%);
	opacity: 0.3;
	object-fit: cover;
`;

const BackgroundRight = styled.img`
	${tw('h-full w-full')};
	-webkit-mask-image: radial-gradient(ellipse 90% 85% at 80% 50%, black 0%, transparent 60%);
	mask-image: radial-gradient(ellipse 90% 85% at 80% 50%, black 0%, transparent 60%);
	opacity: 0.3;
	object-fit: cover;
`;

const AboutDesc = styled.p`${tw('text-white text-md md:text-xl lg:text-xl font-sans text-left')};`;

const ContactText = styled.p`
	${tw('text-grey-light font-sans text-xl md:text-2xl lg:text-3xl')};
	a {
		color: #e07628;
		text-decoration: none;
	}
`;

class Index extends React.Component {
	render() {
		return (
			<React.Fragment>
				<SEO />
				<div>
					<Content>
						<Inner>
							<AboutHero>
								<Avatar src={avatar} alt="Yerai Alonso" />
								<BigTitle>Yerai Alonso.</BigTitle>
								<Subtitle>Atleta de calistenia y creador de contenido.</Subtitle>
							</AboutHero>
						</Inner>

						<Divider>
							<BackgroundRight src={background_fragment_one} />
						</Divider>
					</Content>

					<Content>
						<Inner>
							<Title>#SOBRE MÍ</Title>
							<AboutDesc>
								Atleta de Calistenia y Street Workout, con más de 6 años de experiencia, creador de uno
								de los canales de YouTube más influyentes en el sector así como de la aplicación de
								calistenia más reconocida. Autor del libro “Guía Completa de Calistenia y Street
								Workout”, profesor de cursos de calistenia a nivel nacional, jurado de competiciones de
								calistenia a nivel nacional e internacional, doble campeón de Canarias y competidor a
								nivel nacional.
								<br />
								<br />
								Mi objetivo es la autosuperación, tanto personal como de las personas que quieran
								escuchar mis consejos o que se sientan inspiradas por mi trabajo. Quiero tener un estado
								físico ideal y una salud óptima y ayudar a que otras personas puedan luchar por ese
								objetivo, quiero que todos podamos mejorar día a día a través de la constancia, la
								disciplina, la inteligencia, eficacia y eficiencia, siempre buscando la verdad y todo
								aderezado con un poco de humor, que nunca viene mal.
							</AboutDesc>
						</Inner>
					</Content>

					<Divider>
						<div style={{ height: '30vh' }} />
						<BackgroundLeft src={blogImage} />
					</Divider>

					<Content>
						<Inner>
							<Title>#PROYECTOS</Title>
							<ProjectsWrapper>
								<ProjectCard
									title="Calisteniapp"
									link="https://calisteniapp.com"
									bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
									bgIcon={background_fragment_one}
								>
									La mejor aplicación de calistenia. Rutinas, programas de entrenamiento, desafíos
									mensuales, rutinas inteligentes, artículos, vídeos explicativos y más. En la versión
									gratuita podrás disfrutar de más de 150 rutinas de todo tipo. Cada mes añadimos
									nuevas rutinas, contenido y funcionalidades.
								</ProjectCard>
								<ProjectCard
									title="Youtube"
									link="https://www.youtube.com/c/YeraiStreetWorkout"
									bg="linear-gradient(to right, #b31217 0%, #e52d27 100%)"
									bgIcon={youtubeImage}
								>
									Canal con rutinas, tutoriales, humor, desafíos, retos, consejos, entretenimiento y
									mucho más. Nuevos vídeos cada semana, ¡suscríbete!
								</ProjectCard>
								<ProjectCard
									title="Instagram"
									link="https://www.instagram.com/yeraialonso"
									bg="linear-gradient(to right, #833ab4 0%, #fd1d1d 50%, #fcb045 100%)"
									bgIcon={instagramImage}
								>
									Contenido diario, vídeos de freestyle, mini tutoriales, humor, consejos rápidos y
									cosas de mi día a día.
								</ProjectCard>
								<ProjectCard
									title="Podcast"
									link="https://www.ivoox.com/podcast-podcastenia-yerai-alonso_sq_f1647325_1.html"
									bg="linear-gradient(to right, #673AB7 0%, #512DA8 100%)"
									bgIcon={podcastImage}
								>
									Análisis en profundidad de temas relacionados con la calistenia y el fitness en
									general, entrevistas, reflexiones, preguntas y respuestas etc. Ideal para escucharlo
									mientras realizas otras tareas o incluso mientras entrenas.
								</ProjectCard>

								<ProjectCard
									title="Suplementación y Equipamiento"
									link="https://prozis.com/5ZyR"
									bg="linear-gradient(to right, #93291E 0%, #ED213A 100%)"
									bgIcon={prozisImage}
								>
									Utiliza mi código de descuento YERAI para comprar suplementación, equipamiento
									deportivo, comida saludable y mucho más.
								</ProjectCard>

								<ProjectCard
									title="Mi libro"
									link="https://www.megustaleer.com/libros/la-calle-es-tu-gimnasio/MES-112127"
									bg="linear-gradient(to right, #56ab2f 0%, #a8e063 100%)"
									bgIcon={bookImage}
								>
									Adquiere todos los conocimientos básicos sobre calistenia y Street workout, descubre
									los ejercicios principales de cada categoría, los tipos de entrenamiento y de
									rutinas, cómo crear tus propias rutinas y más.
								</ProjectCard>

                <ProjectCard
                  title="Camisténicas y Equipamiento"
                  link="https://www.tiendastenica.com"
                  bg="linear-gradient(to right, #000000 0%, #434343 100%)"
                  bgIcon={blogImage}
                >
                  Camisetas, Sudaderas, Mini Paralelas y otros artículos con los logotipos de Yerai Street Workout y noGravity Street Workout
                </ProjectCard>

								<ProjectCard
									title="Foro"
									link="https://www.forocalistenia.com"
									bg="linear-gradient(to right, #185a9d 0%, #43cea2 100%)"
									bgIcon={foroImage}
								>
									Lugar de debate, preguntas y respuestas, consejos, donde todos tienen voz. Ideal
									para compartir experiencias
								</ProjectCard>
							</ProjectsWrapper>
						</Inner>
					</Content>

					<div style={{ height: '50vh' }}>
						<Content>
							<Inner>
								<div style={{ height: '5vh' }} />
								<Title>#CONTACTO</Title>
								<ContactText>
									Dime <a href="mailto:yeraialonsoworkout@gmail.com">Hola</a> o sígueme en mis redes
									sociales: <a href="https://www.instagram.com/yeraialonso ">Instagram</a> &{' '}
									<a href="https://www.youtube.com/c/YeraiStreetWorkout">Youtube</a>
								</ContactText>
							</Inner>
						</Content>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Index;
